import { useState } from 'react';

export type Package = {
  controlPlanes: number;
  applications: number;
};

// 300$ for 1 control plane
export const CONTROL_PLANE_PRICE = 300;

// 300$ for 50 apps
export const APPLICATION_PRICE = 6;

export const MAXIMUM_CONTROL_PLANE_ESTIMATION = 5;
export const MAXIMUM_APPLICATION_ESTIMATION = 500;

export const MINIMUM_CONTROL_PLANE = 1;
export const MINIMUM_APPLICATION = 50;

export const CONTROL_PLANE_BUNDLE = 1;
export const APPLICATION_BUNDLE = 50;

export const usePriceEstimator = (initial?: Package) => {
  const [bundle, setBundle] = useState(
    initial || { controlPlanes: 1, applications: 50 }
  );

  const [dedicatedSupport, setDedicatedSupport] = useState(false);

  let estimation = -1;

  if (
    !dedicatedSupport &&
    bundle.controlPlanes <= MAXIMUM_CONTROL_PLANE_ESTIMATION &&
    bundle.applications <= MAXIMUM_APPLICATION_ESTIMATION
  ) {
    estimation =
      CONTROL_PLANE_PRICE * bundle.controlPlanes +
      APPLICATION_PRICE * bundle.applications;
  }

  const incrementControlPlanes = () =>
    setBundle({
      ...bundle,
      controlPlanes: bundle.controlPlanes + CONTROL_PLANE_BUNDLE
    });

  const decrementControlPlanes = () =>
    bundle.controlPlanes - CONTROL_PLANE_BUNDLE >= MINIMUM_CONTROL_PLANE &&
    setBundle({
      ...bundle,
      controlPlanes: bundle.controlPlanes - CONTROL_PLANE_BUNDLE
    });

  const incrementApplications = () =>
    setBundle({
      ...bundle,
      applications: bundle.applications + APPLICATION_BUNDLE
    });

  const decrementApplications = () =>
    bundle.applications - APPLICATION_BUNDLE >= MINIMUM_APPLICATION &&
    setBundle({
      ...bundle,
      applications: bundle.applications - APPLICATION_BUNDLE
    });

  return {
    bundle,
    dedicatedSupport,
    controllers: {
      incrementControlPlanes,
      decrementControlPlanes,
      incrementApplications,
      decrementApplications,
      setDedicatedSupport
    },
    estimation
  };
};
