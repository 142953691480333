import EnterpriseForm from '@/components/EnterpriseForm';
import {
  MAXIMUM_APPLICATION_ESTIMATION,
  MAXIMUM_CONTROL_PLANE_ESTIMATION,
  Package
} from '@/sections/Pricing/usePriceEstimator';
import { seoConfiguration } from '@/utils/seo-config';
import { keys } from '@/utils/storage-keys';

const TalkToSales = () => {
  return (
    <EnterpriseForm
      content={content}
      formGUID="8c88f361-014c-4bd9-936d-8bb646b13f51"
      submitSuccessMessage={content.submitSuccessMessage}
      eventCode="talk_to_sales"
      onFormReady={($form: unknown) => {
        // @ts-ignore
        const inputs = [...$form];

        const controlPlanes = inputs.find((i) => i.name === 'controlplanes');
        const apps = inputs.find((i) => i.name === 'applications');

        const _bundle = sessionStorage.getItem(keys.pricingBundle());

        if (_bundle) {
          const bundle: Package = JSON.parse(_bundle);

          controlPlanes.value =
            bundle.controlPlanes > MAXIMUM_CONTROL_PLANE_ESTIMATION
              ? `${MAXIMUM_CONTROL_PLANE_ESTIMATION}+`
              : bundle.controlPlanes;

          apps.value =
            bundle.applications > MAXIMUM_APPLICATION_ESTIMATION
              ? `${MAXIMUM_APPLICATION_ESTIMATION}+`
              : bundle.applications;
        }
      }}
    />
  );
};

const content = {
  seo: seoConfiguration['talk-to-sales'],
  header: 'Talk to Sales',
  subheader:
    'Ready to get started with the Akuity Platform or Akuity Enterprise?',
  formHelper:
    'Fill out the form and we’ll reach out to you ASAP to help you. Please use your work email!',
  submitSuccessMessage: 'Thanks you for connecting with us.'
};

export default TalkToSales;
